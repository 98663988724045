export const state = () => ({
  me: {
    profile: {
      first_name: {
        value: undefined,
        editable: false,
        displayed: false
      },
      last_name: {
        value: undefined,
        editable: false,
        displayed: false
      },
      gender: {
        value: undefined,
        editable: false,
        displayed: false
      },
      phone: {
        value: undefined,
        editable: false,
        displayed: false
      },
      mobile_phone: {
        value: undefined,
        editable: false,
        displayed: false
      },
      job_title: {
        value: undefined,
        editable: false,
        displayed: false
      },
      language: {
        value: undefined,
        editable: false,
        displayed: false
      },
      role: {
        value: undefined,
        editable: false,
        displayed: false
      }
    },
    security: {
      double_auth: 1,
      allowed_networks: [],
      screening_deletion_period: { period: 90, update: false },
      can_reset_password: false
    },
    personal_dossiers_settings: {
      alerts: {
        selected_alerts: {},
        custom_alerts: undefined,
        update: undefined
      },
      communication_email: undefined
    },
    default_dossiers_options: {
      transparency: {
        progress: undefined,
        result: undefined,
        update: undefined,
        progress_transparency_label: undefined,
        progress_description: undefined,
        result_transparency_label: undefined,
        result_description: undefined,
      },
      alerts: {
        selected_alerts: {},
        custom_alerts: undefined,
        update: undefined
      }
    },
    deletion: false,
    subscription: { name: undefined, update: undefined },
    master_user: {
      first_name: undefined,
      title: undefined,
      last_name: undefined,
      language: undefined,
      language_iso: undefined
    },
    users: { total_count: undefined, actives_count: undefined, max: undefined }
  },
  error: { status: undefined, message: undefined },
  loading: null
})

export const getters = {}
export const mutations = {
  SET_ME(state, payload) {
    state.me = payload
  },
  SET_ERROR(state, payload) {
    state.error = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_VALUE(state, payload) {
    !!payload?.key
      ? (state.me[payload?.namespace][payload?.key] = payload?.value)
      : (state.me[payload?.namespace] = payload?.value)
  },
  SET_VALUE_WITH_PARENT(state, payload) {
    !!payload?.key &&
      !!payload?.parent &&
      (state.me[payload?.namespace][payload?.parent][payload?.key] = payload?.value)
  }
}
export const actions = {
  async getMe({ commit }) {
    const { status, statusText, _data: data } = await this.$api.settings.me()
    !status && status !== 200
      ? commit(`SET_ERROR`, {
          status,
          statusText,
          data
        })
      : commit(`SET_ME`, data)
  },
  async updateSettings({ commit, state, dispatch }, payload) {
    commit(`SET_ERROR`, null)
    commit(`SET_LOADING`, true)

    try {
      const { updateTypeData, messageSuccess = `messages.profile_updated` } = payload
      const updateData = { [updateTypeData]: { ...state.me[updateTypeData] } }
      const { status, statusText, _data: data } = await this.$api.settings.updateMe(updateData)
      if (!status && status !== 200) {
        commit(`SET_ERROR`, {
          status,
          statusText,
          data
        })
        dispatch(`flashMessages/messageError`, { message: this.$i18n.t(`messages.invalid_entries`) }, { root: true })
      } else {
        commit(`SET_ME`, data)
        dispatch(`flashMessages/messageSuccess`, { message: this.$i18n.t(messageSuccess) }, { root: true })
      }
    } catch ({ response, message }) {
      dispatch(`flashMessages/messageError`, { message: this.$i18n.t(`messages.invalid_entries`) }, { root: true })
      commit(`SET_ERROR`, { ...response, message })
    } finally {
      commit(`SET_LOADING`, false)
    }
  },
  async deleteAccount({ commit, dispatch }, me) {
    commit(`SET_ERROR`, null)
    commit(`SET_LOADING`, true)
    try {
      await this.$api.settings.accountDeletion()
      commit(`SET_VALUE`, { namespace: `deletion`, value: true })
      dispatch(`flashMessages/messageSuccess`, { message: this.$i18n.t(`messages.action_confirmed`) }, { root: true })
    } catch ({ response, message }) {
      dispatch(`flashMessages/messageError`, { message: this.$i18n.t(`messages.invalid_entries`) }, { root: true })
      commit(`SET_ERROR`, { ...response, message })
    } finally {
      commit(`SET_LOADING`, false)
    }
  }
}
