const EXTERNAL_NAME_ROUTE = `external`
const isExternalNameRoute = (name) => name === EXTERNAL_NAME_ROUTE

export default async ({ $auth, store, route }) => {
  if (isExternalNameRoute(route?.name) || store.getters[`permissions/userIsExternal`]) {
    // $auth.strategy.token.reset()
    return
  }

  if (!!$auth.ctx.route.query.lang) {
    await $auth.ctx.app.i18n.setLocale($auth.ctx.route.query.lang)
  }

  if (!$auth?.loggedIn) {
    store.dispatch(`logout`)
    return
  }

  if (!!$auth.user) {
    if ($auth.user.companies) {
      store.commit(`companies/setUserCompanies`, $auth.user.companies)
    }
    if ($auth.user?.candidate_screenings?.length === 1) {
      store.commit(`menu/updateMenuState`, {
        name: `your_screening`,
        updatedValue: { to: `/screenings/${$auth.user?.candidate_screenings}` }
      })
    }

    if (!!$auth.user.lang && !$auth.ctx.route.query.lang) {
      await $auth.ctx.app.i18n.setLocale($auth.user.lang)
    }
  }
}
