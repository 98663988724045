// Screenings Store
export const state = () => ({
  data: {},
  newSaved: [],
  lastScreening: null
})

export const getters = {
  newSaved: (state) => (compagny) => state?.newSaved?.[compagny] || null,
  getLastScreening: (state) => state.lastScreening,
  getData: (state) => (id) => {
    if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id)) {
      return !!state.data[id] ? JSON.parse(state.data[id]) : ``
    } else {
      throw new Error(`${id} is not a valid UUID!`)
    }
  }
}

export const mutations = {
  cleanLastUserData(state) {
    state.data = {}
    state.newSaved = []
    state.lastScreening = null
  },
  saveNew(state, { data, company }) {
    if (!!data && !!company) {
      state.newSaved = { ...state.newSaved, [company]: data }
    }
  },
  cleanNewSaved(state, company) {
    if (!!company) {
      state.newSaved = { ...state.newSaved, [company]: null }
    } else {
      state.newSaved = []
    }
  },
  setData(state, scrData, force = false) {
    if (!!scrData && !!scrData.id) {
      if (force || state.data[scrData.id] === undefined) {
        state.data = { ...state.data, [scrData.id]: {} }
      }
      state.data[scrData.id] = JSON.stringify(scrData)
    }
  },
  delData(state) {
    state.data = {}
  },
  setLastScreening(state, id) {
    if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id)) {
      state.lastScreening = id
    } else {
      throw new Error(`${id} is not a valid UUID!`)
    }
  }
}

export const actions = {
  delScreeningLocal({ commit, dispatch }) {
    commit(`delData`)
    dispatch(`flashMessages/messageSuccess`, { message: this.$i18n.t(`messages.action_confirmed`) }, { root: true })
  }
}
