import HttpFactory from '../factory'

class BillingModule extends HttpFactory {
  RESOURCE = `/billing`
  async getCompagnySettings(companyTag) {
    return await this.call(`GET`, `${this.RESOURCE}/company/${companyTag}/`)
  }

  async updateCompagnySettings(companyTag, params) {
    return await this.call(`put`, `${this.RESOURCE}/company/${companyTag}/`, params)
  }
}

export default BillingModule
