import HttpFactory from '../factory'

class ContactModule extends HttpFactory {
  RESOURCE = `/contact`
  async getRefundContactByUUID(uuid) {
    return await this.call(`get`, `${this.RESOURCE}/refund/${uuid}`)
  }

  async send(form) {
    return await this.call(`post`, `${this.RESOURCE}/`, form)
  }

  async refund(form) {
    return await this.call(`post`, `${this.RESOURCE}/refund/`, form)
  }
}

export default ContactModule
