
export default {
  name: `RatingModal`,
  props: {
    show: {
      type: Boolean,
      required: false
    },
  },
  data() {
    return {
      localShow: this.show,
      score: 0,
      comment: ``,
      commentError: null,
      scoreError: null,
    }
  },
  watch: {
    show(newVal) {
      this.localShow = newVal
    },
    localShow(newVal) {
      if (!newVal) {
        this.sendFeedback()
        this.$emit(`update:localShow`, newVal)
      }
    }
  },
  methods: {
    close() {
      this.localShow = false
    },
    async sendFeedback() {
      try {
        this.commentError = null
        this.scoreError = null

        await this.$api.rating.sendFeedback({
          score: this.score,
          comment: this.comment,
        })
        this.localShow = false
      } catch (error) {
        if (error.response && error.response._data) {
          const errorData = error.response._data
          if (errorData.comment) {
            this.commentError = errorData.comment[0]
          }
          if (errorData.score) {
            this.scoreError = errorData.score[0]
          }
          this.localShow = true
        }
      }
    },
  }
}
