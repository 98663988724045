const DEFAULT_SELECTED_CHECKS = []
const DEFAULT_SELECTED_MY_CHECK = undefined
const DEFAULT_CHECKS = undefined
const DEFAULT_MY_CHECKS = undefined
const DEFAULT_TYPE_OF_DOSSIER = undefined
const DEFAULT_JOB_LOCATION = `CH`
const DEFAULT_REASON = undefined
const DEFAULT_DOSSIER = undefined
const DEFAULT_CATEGORY = undefined
const DEFAULT_FILTERS= {}
const DEFAULT_CANDIDATE_FORM = undefined
const DEFAULT_CANDIDATE_FORM_ANSWERS = undefined

export const state = () => ({
  jobLocations: undefined,
  reasons: undefined,
  dossiers: undefined,
  categories: undefined,
  checks: DEFAULT_CHECKS,
  filters: undefined,
  myChecks: DEFAULT_MY_CHECKS,
  candidateForm: DEFAULT_CANDIDATE_FORM,
  candidateFormAnswers: DEFAULT_CANDIDATE_FORM_ANSWERS,
  joined_documents: [],
  multiple_allowed: undefined,
  available_credits: 0,

  selected: {
    jobLocation: DEFAULT_JOB_LOCATION,
    reason: DEFAULT_REASON,
    dossier: DEFAULT_DOSSIER,
    category: DEFAULT_CATEGORY,
    checks: DEFAULT_SELECTED_CHECKS,
    filters: DEFAULT_FILTERS,
    myCheck: DEFAULT_SELECTED_MY_CHECK,
    candidateInfo: undefined
  }
})

export const getters = {
  jobLocations(state) {
    return state.jobLocations
  },
  reasons(state) {
   return state.reasons
  },
  dossiers(state)  {
    return state.dossiers
  },
  categories: (state) => {
    return state.categories
  },
  candidateForm: (state) => {
    return state.candidateForm
  },
  checks: (state) => {
    return state.checks
  },
  myChecks: (state) => {
    return state.myChecks
  },
  languages: (state) => {
    return state.filters?.languages ?? []
  },
  checkCategories: (state) => {
    return state.filters?.checkCategories ?? []
  },
  filters: (state) => {
   return state?.filters ?? DEFAULT_FILTERS
  },

  selection(state){
    return state.selected
  },
  selectedJobLocation: (state) => {
    return state.selected?.jobLocation
  },
  selectedReason: (state) => {
    return state.selected?.reason
  },
  selectedDossier: (state) => {
    return state.selected?.dossier
  },
  selectedCategory: (state) => {
    return state.selected?.category
  },
  selectedChecks: (state) => {
    return state.selected?.checks ?? DEFAULT_SELECTED_CHECKS
  },
  selectedMyCheck: (state) => {
    return state.selected.myCheck
  },
  selectedFilters: (state) => {
    return state.selected?.filters ?? DEFAULT_FILTERS
  },
  candidateFormsAnswers: (state) => {
    return state.candidateFormAnswers ?? {}
  },
  totalCredits(state, getters) {
    let sum = 0
    getters.selectedChecks.forEach((el) => (sum += el.credits))
    return sum
  },
  available_credits(state) {
    return state.available_credits ?? 0
  },
  joined_documents(state) {
    return state.joined_documents
  }
}

export const mutations = {
  jobLocations(state, jobLocations) {
    state.jobLocations = jobLocations
  },
  reasons(state, reasons) {
    state.reasons = reasons
  },
  dossiers(state, dossiers)  {
    state.dossiers = dossiers
  },
  categories(state, categories) {
    state.categories = categories
  },
  candidateForm(state, form)  {
    state.candidateForm = form
  },
  candidateFormAnswers(state, answers) {
    state.candidateFormAnswers = answers
  },
  checks(state, checks) {
    state.checks = checks
  },
  myChecks(state, myChecks) {
    state.myChecks = myChecks
  },
  filters(state, filters) {
    state.filters = filters ?? {}
  },
  selectedJobLocation(state, jobLocation) {
    state.selected.jobLocation = jobLocation
  },
  selectedReason(state, reason) {
    state.selected.reason = reason
  },
  selectedDossier(state, dossier) {
    state.selected.dossier = dossier
  },
  selectedCategory(state, category) {
    state.selected.category = category
  },
  selectedChecks(state, checks) {
    state.selected.checks = checks
  },
  selectedMyCheck(state, myCheck) {
    state.selected.myCheck = myCheck
  },
  selectedFilters(state, filters) {
    state.selected.filters = filters
  },
  defaultCandidateForm(state) {
    state.candidateForm = DEFAULT_CANDIDATE_FORM
  },
  defaultCandidateFormAnswers(state) {
    state.candidateFormAnswers = DEFAULT_CANDIDATE_FORM_ANSWERS
  },
  defaultSelectedCategory(state) {
    state.selected.category = DEFAULT_CATEGORY
  },
  defaultSelectedChecks(state) {
    state.selected.checks = DEFAULT_SELECTED_CHECKS
  },
  defaultSelectedMyCheck(state){
    state.selected.myCheck = DEFAULT_SELECTED_MY_CHECK
  },
  defaultSelectedJobLocation(state) {
    state.selected.jobLocation = DEFAULT_JOB_LOCATION
  },
  defaultSelectedDocument(state) {
    state.selected.dossier = DEFAULT_TYPE_OF_DOSSIER
  },
  defaultSelectedReason(state) {
    state.selected.reason = DEFAULT_REASON
  },
  defaultChecks(state){
    state.checks = DEFAULT_CHECKS
  },
  defaultMyChecks(state) {
    state.myChecks = DEFAULT_MY_CHECKS
  },
  defaultSelectedFilters(state) {
    state.selected.filters = DEFAULT_FILTERS
  },
  available_credits(state, value) {
    state.available_credits = value ?? 0
  },
  updateCandidateAnswer(state, payload) {
    const {name, value} = payload
    state.candidateFormAnswers[name] = value
  },
  joined_documents(state, value) {
    state.joined_documents = value
  },
}

export const actions = {

  async getReasonsAndDossiers({ commit, getters}){
    const res = (!getters?.reasons || !getters?.dossiers || !getters?.jobLocations) && await this.$api.formData.marketplaceOrderCheck()
  
    !!res && await commit(`jobLocations`, res?.job_locations);
    !!res && await commit(`reasons`, res?.reasons);
    !!res && await commit(`dossiers`, res?.reason_categories);
    return { jobLocations: getters.jobLocations, reasons: getters.reasons, dossiers: getters.dossiers }
  },

  async getCategories({ commit, getters }) {
    const res = !getters.categories && await this.$api.marketplace.getCheckCategories()
    !getters.categories && await commit(`categories`, res)
    return getters.categories
  },

  async getChecks({ commit, getters }, payload) {
    const { category, filters } = payload
    const res = await this.$api.marketplace.getChecks(category?.id ?? getters.selectedCategory.id, filters)
    if (!!category) {
      await commit(`selectedCategory`, category)
      await commit(`defaultSelectedFilters`)
      await commit(`filters`, category.filters)
      await commit(`defaultSelectedChecks`)
      await commit(`defaultCandidateForm`)
    }
    await commit(`checks`, res)
    return res
  },

  getMyChecks({ commit, getters }, filters) {
    return this.$api.marketplace.getAllMyChecks(filters).then( async (res) => {
      await commit(`myChecks`, res)
      return Promise.resolve(getters.myChecks)
    }).catch( (error) => {
      return Promise.reject(error)
    })
  },

  getMyCheck({ commit, getters }, id){
    return this.$api.marketplace.getMyCheckDetails(id).then( async (res) => {
      const candidateAnswers = res.form?.answers ?? {}
      const formattedAnswers = Object.assign({}, ...Object.keys(candidateAnswers).map(k => ({ [k]: candidateAnswers[k].answer })))
      await commit(`selectedMyCheck`, res)
      await commit(`candidateFormAnswers`, formattedAnswers)
      await commit(`candidateForm`, res?.form?.questions)
      await commit(`joined_documents`, res?.joined_documents ?? [])
      return Promise.resolve(getters.selectedMyCheck)
    }).catch((error) => {
      return Promise.reject(error)
    })
  },

  async addSelectedCheck({ commit, getters }, check) {
    let selectedChecks = [...getters.selectedChecks]
    const alreadySelected = selectedChecks.filter((item) => item.name === check.name).length
    const multipleAllowed = !!getters.selectedCategory.select_multiple

    selectedChecks = alreadySelected
      ? selectedChecks.filter((item) => item.name !== check.name)
      : multipleAllowed
      ? [...selectedChecks, ...[check]]
      : [check]

    await commit(`selectedChecks`, selectedChecks)
    return selectedChecks
  },

  async removeSelectedCheck({ commit, getters }, check) {
    await commit(
      `selectedChecks`,
      getters.selectedChecks.filter((i) => i.id !== check.id)
    )
    return getters.selectedChecks
  },

  async getCandidateForm({ commit, getters }, payload){
    const res = await this.$api.marketplace.getCandidateFom(payload)
    const cAnswers = Object.assign({}, ...Object.keys(res.form ?? {}).map(k => ({[k]: undefined})))
    const currentAnswers = getters.candidateFormsAnswers
    await commit(`defaultCandidateForm`)
    await commit(`candidateForm`, res.form)
    // use the same filled values in params if already in
    await commit(`candidateFormAnswers`, { ... cAnswers, ...currentAnswers })
    await commit(`joined_documents`, res?.joined_documents ?? [])
    return res
  },

  orderNewCheck(context, payload) {
    const { form } = payload
    return this.$api.marketplace.newCheckOrder(form)
  },

  cancelCheck({ dispatch, getters }, id) {
    return this.$api.marketplace.cancelCheck(id).then(async (res) => {
      await dispatch(`getMyCheck`, id)
      return Promise.resolve(getters.selectedMyCheck)
    }).catch((error) => {
      return Promise.reject(error)
    })
  }
}
