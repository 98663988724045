import HttpFactory from '../factory'

class AlertConfigurationModule extends HttpFactory {
  RESOURCE = `/alert_configuration`

  async updateAlerts(screeningId, alertsData) {
    return await this.call(`put`, `${this.RESOURCE}/screening/${screeningId}/`, alertsData)
  }

  async getScreening(screeningId) {
    return await this.call(`get`, `${this.RESOURCE}/screening/${screeningId}/`)
  }

  async getClientUser() {
    return await this.call(`get`, `${this.RESOURCE}/client_user/`)
  }

  async getClientUserByCompany(company) {
    return await this.call(`get`, `${this.RESOURCE}/client_user/${company}`)
  }
}

export default AlertConfigurationModule
