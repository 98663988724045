
  import { mapState } from 'vuex'

  export default {
    name: `AddCredits`,
    props: {},
    data() {
      return {
        loading: false,
        newCredits: undefined,
        addCreditsModal: false
      }
    },
    computed: {
      ...mapState({
        currency: (state) => state.accounting.settings.currency,
        invoiceModes: (state) => state.accounting.settings.invoice_mode || ``
      }),
      companyTag() {
        return this.$auth.user.current_company
      }
    },
    async fetch() {
      await this.$store.dispatch(`accounting/getSettings`, { companyTag: this.companyTag })
    },
    methods: {
      closeModalAddCredits() {
        this.newCredits = undefined
        this.addCreditsModal = false
        this.loading = false
      },
      async addCredits() {
        const ref = this?.$refs.newCreditsModalObs
        const creditValidate = await ref?.validate()
        if (!!creditValidate) {
          try {
            this.loading = true
            const res = await this.$store.dispatch(`accounting/addCredits`, {
              amount: this.newCredits
            })
            this.closeModalAddCredits()
            if (res.status === `error`) {
              throw new Error(res.msg)
            }
            await this.$store.dispatch(`accounting/getSettings`, { companyTag: this.companyTag })
            this.$store.dispatch(
              `flashMessages/messageSuccess`,
              {
                message: this.$i18n.t(
                  `pages.settings.Thank_you_your_account_has_been_charged_don_t_hesitate_to_ask_us_if_you_have_any_questions`
                )
              },
              { root: true }
            )
          } catch (error) {
            this.$store.dispatch(
              `flashMessages/messageError`,
              {
                message: error.message
              },
              { root: true }
            )
          }
        }
      },
      openModalAddCredits() {
        this.addCreditsModal = true
      }
    }
  }
