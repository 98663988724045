export const state = () => ({
  stepErrors: []
})

const stepErrorWithoutID = (state, id) => {
  return state.stepErrors.filter((stepError) => stepError.id !== id)
}

export const getters = {}
export const mutations = {
  SET_LIST(state, payload) {
    state.stepErrors = payload
  },
  ADD_LIST(state, payload) {
    state.stepErrors = [
      ...stepErrorWithoutID(state, payload.id),
      {
        id: payload.id,
        text: payload.text
      }
    ]
  },
  REMOVE_LIST(state, payload) {
    state.stepErrors = [...stepErrorWithoutID(state, payload.id)]
  }
}
export const actions = {}
