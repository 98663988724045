import { useNuxtApp } from '#app'

export const state = () => ({
  query: ``,
  declarations: {
    name: ``,
    code: ``,
    declarations: [],
  },
})

export const getters = {
  declarations: (state, url) => state.declarations,
}

export const mutations = {
  setDeclarations(state, declarations) {
    state.declarations = declarations 
  }
}

function isExternal() {
  const { store } = useNuxtApp().nuxt2Context
  return store.getters[`permissions/userIsExternal`]
}

export const actions = {

  async getDeclarations({ commit }, {code, lang=undefined}) {
    let data;
    if (isExternal()) {
      data = await this.$api.daSignerExternal.getDeclarations(code, lang)
    } else {
      data = await this.$api.daSigner.getDeclarations(code)
    }
    commit(`setDeclarations`, data)
  },

  async generateDA({ commit }, {data, lang=undefined}) {
    if (isExternal()) {
      return await this.$api.daSignerExternal.generateDA(data, lang);
    }
    return await this.$api.daSigner.generateDA(data);
  }
}
