import HttpFactory from '../factory'

class ScreeningAnswersMobule extends HttpFactory {
  RESOURCE = `/screening_answers`

  async getExternal(answerId) {
    return await this.call(`get`, `${this.RESOURCE}/${answerId}/externals/`)
  }

  async sendAnswer(answerId, data) {
    return await this.call(`put`, `${this.RESOURCE}/${answerId}/`, data)
  }

  async delete(answerId) {
    return await this.call(`delete`, `${this.RESOURCE}/${answerId}/`)
  }
}

export default ScreeningAnswersMobule
