export const state = () => ({
  users: [],
  error: null,
  loading: null,
  newUser: {},
  userLimit: false,
  hasAbsenceManagementFeature: false,
  filter: 1
})

export const getters = {
  usersList: (state) => {
    return state.filter === 0
      ? state.users
      : state.filter === 1
      ? state.users.filter((user) => user.is_active)
      : state.users.filter((user) => !user.is_active)
  },
  userLimit: (state) => {
    return state.userLimit
  },
  hasAbsenceManagementFeature: (state) => {
    return state.hasAbsenceManagementFeature
  },
  filterActiveUsers: (state) => {
    return state.filter
  }
}
export const mutations = {
  setUsers(state, payload) {
    state.users = payload.users.sort((a, b) => !a.is_active - !b.is_active)
    state.userLimit = payload.at_limit
    state.hasAbsenceManagementFeature = payload.has_absence_management
  },
  setUser(state, payload) {
    !!payload?.key
      ? (state.users[payload?.index][payload?.key] = payload?.value)
      : (state.users[payload?.index] = payload?.value)
  },
  setfilterActiveUsers(state, payload) {
    state.filter = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  setLoading(state, payload) {
    state.loading = payload
  },
  setLoadingNewUser(state, payload) {
    state.loadingNewUser = payload
  }
}
export const actions = {
  async getUsers({ commit, dispatch }, payload = { displayError: true }) {
    commit(`setError`, null)
    commit(`setLoading`, true)
    try {
      const { status, statusText, _data: data } = await this.$api.client.getUsers()
      if (!status && status !== 200) {
        dispatch(`flashMessages/messageError`, { message: data.message }, { root: true })
        commit(`setError`, { status, statusText, data })
      } else {
        commit(`setUsers`, data)
      }
    } catch (error) {
      if (payload?.displayError === true) {
        dispatch(`flashMessages/messageError`, { message: error.message }, { root: true })
      }
      commit(`setError`, error)
    } finally {
      commit(`setLoading`, false)
    }
  },
  async createNewUser({ commit, dispatch }, payload) {
    const { user, callbackSuccess, callbackError } = payload
    commit(`setError`, null)
    try {
      const { status, statusText, _data: data } = await this.$api.client.createNewUser(user)
      if (!status && status !== 200) {
        dispatch(`flashMessages/messageError`, { message: data.message }, { root: true })
        commit(`setError`, { status, statusText, data })
      } else {
        dispatch(`flashMessages/messageSuccess`, { message: this.$i18n.t(`messages.user_created`) }, { root: true })
        callbackSuccess()
      }
    } catch (error) {
      dispatch(`flashMessages/messageError`, { message: this.$i18n.t(`messages.invalid_entries`) }, { root: true })
      commit(`setError`, error.message)
      callbackError(error)
    }
  },

  async updateUser({ commit, dispatch }, payload) {
    const { user, callbackSuccess, callbackError } = payload
    commit(`setError`, null)
    try {
      const { status, statusText, _data: data } = await this.$api.client.updateUser(user)
      if (!status && status !== 200) {
        dispatch(`flashMessages/messageError`, { message: data.message }, { root: true })
        commit(`setError`, { status, statusText, data })
      } else {
        dispatch(`flashMessages/messageSuccess`, { message: this.$i18n.t(`messages.user_edited`) }, { root: true })
        callbackSuccess()
      }
    } catch (error) {
      const message = error?.data?.detail || this.$i18n.t(`messages.invalid_entries`)
      dispatch(`flashMessages/messageError`, { message }, { root: true })
      commit(`setError`, error.message)
      callbackError(error)
    }
  },
  async sendPassword({ commit, dispatch }, payload) {
    commit(`setError`, null)
    try {
      const { status, _data: data } = await this.$api.client.resetPassword(payload?.email)
      if (!status && status !== 200) {
        commit(`setError`, { error: data.message })
        dispatch(`flashMessages/messageError`, { message: data.message }, { root: true })
      } else {
        dispatch(`flashMessages/messageSuccess`, { message: data.message }, { root: true })
      }
    } catch (error) {
      dispatch(`flashMessages/messageError`, { message: error.message }, { root: true })
      commit(`setError`, error.message)
    }
  }
}
