import HttpFactory from '../factory'

class FormDataModule extends HttpFactory {
  RESOURCE = `/form_data`
  async screeningSearch() {
    return await this.call(`get`, `${this.RESOURCE}/screening_search`)
  }

  async settings() {
    return await this.raw(`get`, `${this.RESOURCE}/settings/`)
  }

  async billingAddressOptions() {
    return await this.call(`get`, `${this.RESOURCE}/settings/billing/`)
  }

  async marketplaceOrderCheck() {
    return await this.call(`get`, `${this.RESOURCE}/marketplace_check/`)
  }
}

export default FormDataModule
