// Marketplace External Store
export const state = () => ({
  token: null,
  form: {},
  lang: null,
  headerMessage: null
})

export const getters = {
  getToken: (state) => state.token,
  getData: (state) => (token) => !!state.form?.[token] ? JSON.parse(state.form?.[token]) : null
}

export const mutations = {
  setToken(state, value) {
    const token = !!value ? value : null
    localStorage.setItem(`external.token`, token)
    state.token = token
  },
  setData(state, { token, data }) {
    if (state.form === null) {
      state.form = {}
    }
    state.form[token] = JSON.stringify(data)
  },
  removeData(state, token) {
    delete state.form[token]
  },
  setLang(state, value) {
    state.lang = !!value ? value : null
  },
  setHeaderMessage(state, value) {
    state.headerMessage = !!value ? value : null
  },
  clearToken(state) {
    localStorage.removeItem(`external.token`)
    state.token = null
  },
}

export const actions = {
  async getExternal({ commit, state }) {
    return await this.$api.marketplaceExternal.get()
  },
  async submitForm({ commit, state }, data) {
    return await this.$api.marketplaceExternal.post(data)
  }
}
