const middleware = {}

middleware['drawer'] = require('../middleware/drawer.js')
middleware['drawer'] = middleware['drawer'].default || middleware['drawer']

middleware['permissions'] = require('../middleware/permissions.js')
middleware['permissions'] = middleware['permissions'].default || middleware['permissions']

middleware['refeshAutoLogout'] = require('../middleware/refeshAutoLogout.js')
middleware['refeshAutoLogout'] = middleware['refeshAutoLogout'].default || middleware['refeshAutoLogout']

middleware['screening'] = require('../middleware/screening.js')
middleware['screening'] = middleware['screening'].default || middleware['screening']

export default middleware
