import HttpFactory from '../factory'

class ScreeningStepsModule extends HttpFactory {
  RESOURCE = `/screening_steps`

  async get(id) {
    return await this.call(`get`, `${this.RESOURCE}/${id}/`)
  }

  async new(stepId) {
    return await this.call(`post`, `${this.RESOURCE}/${stepId}/new/`)
  }

  async send(stepId, step) {
    console.log(`send`)
    return await this.call(`put`, `${this.RESOURCE}/${stepId}/`, step)
  }
}

export default ScreeningStepsModule
