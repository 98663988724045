import HttpFactory from '../factory'

class SettingsModule extends HttpFactory {
  RESOURCE = `/settings`
  async me() {
    return await this.raw(`get`, `${this.RESOURCE}/me/`)
  }

  async updateMe(data) {
    return await this.raw(`put`, `${this.RESOURCE}/me/`, data)
  }

  async accountDeletion() {
    return await this.raw(`delete`, `/request_account_deletion/`)
  }
}

export default SettingsModule
