import HttpFactory from '../factory'

class AddressesModule extends HttpFactory {
  RESOURCE = `/addresses`

  async addCompagnyAddress(companyTag, params) {
    return await this.call(`post`, `${this.RESOURCE}/company/${companyTag}/create/`, params)
  }

  async getCompagnyAddresses(companyTag) {
    return await this.call(`get`, `${this.RESOURCE}/company/${companyTag}/list/`)
  }

  async updateCompagnyAddress(companyTag, uuid, params) {
    return await this.call(`put`, `${this.RESOURCE}/company/${companyTag}/update/${uuid}/`, params)
  }
}

export default AddressesModule
