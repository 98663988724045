import HttpFactory from '../factory'

class CommentsModule extends HttpFactory {
  RESOURCE = `comments`
  async get(url) {
    return await this.call(`get`, `${url}/${this.RESOURCE}/`)
  }

  async export(settings) {
    return await this.call(`put`, `/export/${this.RESOURCE}/`, settings)
  }

  async saveNewCommentOnScreening(screeningId, newComment) {
    return await this.call(`post`, `/screenings/${screeningId}/${this.RESOURCE}/`, newComment)
  }

  // delete comment by id on screening
  async deleteOnScreening({ screeningId, commentId }) {
    return await this.raw(`delete`, `/screenings/${screeningId}/${this.RESOURCE}/${commentId}/`)
  }
}

export default CommentsModule
