import HttpFactory from '../factory'

class SubscriptionsModule extends HttpFactory {
  RESOURCE = `/subscriptions`
  async getList() {
    return await this.call(`get`, `${this.RESOURCE}/list`)
  }
}

export default SubscriptionsModule
