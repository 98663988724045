import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const EXTERNAL_NAME_ROUTE = `external`
const DEFAULT_KEY_STORE = `aeq-data`
const EXTERNAL_KEY_STORE = `aeq-data-external`
const DEFAULT_PATH = [`screenings`, `lastLogin`, `redirect`, `needFetchUser`]
const EXTERNAL_PATH = [`external`]

const ls = new SecureLS({ isCompression: false })

const isExternalNameRoute = (name) => name === EXTERNAL_NAME_ROUTE

const getStoreName = (route) => {
  const { name } = route
  return isExternalNameRoute(name) ? EXTERNAL_KEY_STORE : DEFAULT_KEY_STORE
}

const getPathName = (route) => {
  const { name } = route
  return isExternalNameRoute(name) ? EXTERNAL_PATH : DEFAULT_PATH
}

export default ({ store, route }) => {
  const keyLocalStorage = getStoreName(route)
  createPersistedState({
    key: keyLocalStorage,
    paths: getPathName(route),
    storage: {
      getItem: (key) => {
        const item = ls.get(key)
        return !!item ? JSON.parse(ls.get(key)) : undefined
      },
      setItem: (key, value) => {
        let data
        try {
          data = ls.set(key, JSON.stringify(value))
        } catch (e) {
          console.error(e)
        }
        return data
      },
      removeItem: (key) => ls.remove(key)
    }
  })(store)
}
