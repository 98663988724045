

export default {
  name: `ReleaseNoteModal`,
  props: {
    show: {
      type: Boolean,
      required: false
    },
    currentRelease: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      releases: [this.currentRelease],
      shownIndex: 0,
      previousLoading: true,
    }
  },
  mounted() {
    this.getPreviousRelease()
  },
  methods: {
    async close() {
      if (!this.currentRelease.seen) {
        await this.$api.releaseNotes.seenReleaseNote()
        this.currentRelease.seen = true
      }
      this.$emit(`update:show`, false)
    },
    async getPreviousRelease() {
      this.previousLoading = true
      if(this.releases[this.shownIndex].previous_version) {
        const previousRelease = await this.$api.releaseNotes.getReleaseNote(
          this.releases[this.shownIndex].previous_version
        )
        if(previousRelease) {
          this.releases.unshift(previousRelease)
          this.shownIndex += 1
        }
      }
      this.previousLoading = false
    },
    show_previous() {
      if(this.previousLoading) return
      this.shownIndex -= 1
      if(this.shownIndex === 0) this.getPreviousRelease()
    },
    show_next() {
      if(this.shownIndex === this.releases.length - 1) return
      this.shownIndex += 1
    }
  }
}
