import Vue from 'vue'
import moment from 'moment'
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate'

import {
  between,
  confirmed,
  digits,
  dimensions,
  double,
  email,
  excluded,
  ext,
  image,
  integer,
  is,
  // eslint-disable-next-line camelcase
  is_not,
  length,
  max,
  // eslint-disable-next-line camelcase
  max_value,
  mimes,
  min,
  // eslint-disable-next-line camelcase
  min_value,
  numeric,
  oneOf,
  regex,
  required,
  // eslint-disable-next-line camelcase
  required_if,
  size
} from 'vee-validate/dist/rules'
import PhoneNumber from 'awesome-phonenumber'

export default ({ app, i18n }) => {
  // Load rules
  const rules = {
    between,
    confirmed,
    digits,
    dimensions,
    double,
    email,
    excluded,
    ext,
    image,
    integer,
    is,
    // eslint-disable-next-line camelcase
    is_not,
    length,
    max,
    // eslint-disable-next-line camelcase
    max_value,
    mimes,
    min,
    // eslint-disable-next-line camelcase
    min_value,
    numeric,
    oneOf,
    regex,
    required,
    // eslint-disable-next-line camelcase
    required_if,
    size
  }

  Object.keys(rules).forEach((rule) => {
    // eslint-disable-next-line import/namespace
    extend(rule, rules[rule])
  })

  configure({
    defaultMessage: (field, values) => {
      return i18n.t(`messages.validations.${values._rule_}`, values)
    }
  })

  extend(`before`, {
    params: [`limit`, `included`],
    validate: (value, { limit, included }) => {
      limit = moment(limit)
      value = moment(value)
      return limit.isValid() && value.isValid()
        ? included
          ? value.isSameOrBefore(limit)
          : value.isBefore(limit)
        : true
    },
    message: (fieldName, placeholders) => {
      const limit = moment(placeholders.limit).format(`DD.MM.YYYY`)
      return i18n.t(`messages.validations.date_before`, { date: limit })
    }
  })

  extend(`after`, {
    params: [`limit`, `included`],
    validate: (value, { limit, included }) => {
      limit = moment(limit)
      value = moment(value)
      return limit.isValid() && value.isValid() ? (included ? value.isSameOrBefore(limit) : value.isAfter(limit)) : true
    },
    message: (fieldName, placeholders) => {
      const limit = moment(placeholders.limit).format(`DD.MM.YYYY`)
      return i18n.t(`messages.validations.date_after`, { date: limit })
    }
  })

  extend(`requireDocAEQ`, {
    validate: (value) => {
      if (!value) {
        return false
      }

      if (Array.isArray(value)) {
        return value.length > 0
      }

      if (!!value.new || !!value.saved) {
        const newDoc = !!value.new && value.new.length > 0
        const savedDoc = !!value.saved && value.saved.length > 0
        return newDoc || savedDoc
      }

      return false
    },
    message: `messages.no_document`
  })

  extend(`formatDocAEQ`, {
    validate: (value, args) => {
      if (!!value && !!args.extensions && args.extensions.length > 0) {
        const multiple = !!value.new
        value = multiple ? value.new : value
        if (value.length > 0) {
          for (const f of value) {
            if (
              (multiple && !args.extensions.includes(f.name.split(`.`).pop())) ||
              (!multiple && !args.extensions.includes(f.fileName.split(`.`).pop()))
            ) {
              return false
            }
          }
        }
      }
      return true
    },
    params: [`extensions`],
    message: `messages.wrong_document_format`
  })

  extend(`sizeDocAEQ`, {
    validate: (value, args) => {
      if (!!value && !!args.size) {
        const multiple = !!value.new
        value = multiple ? value.new : value
        if (value.length > 0) {
          const limit = parseInt(args.size)
          if (!isNaN(limit)) {
            for (const f of value) {
              if (f.size > limit) {
                return false
              }
            }
          }
        }
      }
      return true
    },
    params: [`size`],
    message: `messages.wrong_document_size`
  })

  extend(`positiveNumbers`, {
    validate: (value, args) => {
      const parsedValue = Number(value)
      if (Number.isInteger(parsedValue) && parsedValue >= 0) {
        return true
      }
      return false
    },
    params: [`value`],
    message: i18n.t(`messages.validations.numeric`)
  })

  extend(`formatDate`, {
    validate: (value, args) => {
      const date = app.$moment(value, [`YYYY-MM`, `YYYY-MM-DD`, `MM.YYYY`, `DD.MM.YYYY`, `M.YYYY`, `D.M.YYYY`], true)

      if (!!date && date.isValid()) {
        return true
      }
      return false
    },
    params: [`value`],
    message: i18n.t(`messages.validations.date_format`)
  })

  extend(`phoneNumber`, {
    message: (fieldName, placeholders) => {
      return i18n.t(`messages.validations.phone_format`, { phone: fieldName })
    },
    params: [`value`],
    validate(value, args) {
      return new Promise((resolve) => {
        const phone = new PhoneNumber(value)
        resolve({ valid: phone.isValid() })
      })
    }
  })

  extend(`consistentDate`, {
    validate: (value, args) => {
      const dateNow = new Date()
      const hundredYearsAgo = new Date(new Date().setFullYear(dateNow.getFullYear() - 100))
      const tenYearsFromNow = new Date(new Date().setFullYear(dateNow.getFullYear() + 10))
      const dateUser = new Date(value)

      if (hundredYearsAgo < dateUser && dateUser < tenYearsFromNow) {
        return true
      }
      return false
    },
    params: [`value`],
    message: (fieldName, placeholders) => {
      const dateNow = new Date()
      const dateUser = new Date(placeholders._value_)

      return dateUser < dateNow
        ? i18n.t(`messages.validations.dateBeLowerThan100`)
        : i18n.t(`messages.validations.greaterThan10Years`)
    }
  })

  extend(`maxOptions`, {
    validate: (value, args) => {
      return (value?.length ?? 1) <= (args?.size ?? 1)
    },
    params: [`value`, `size`, `message`],
    message: (fieldName, placeholders) => {
      if (!!placeholders?.message) {
        return placeholders?.message
      }
      return i18n.t(`messages.validations.max_value`, { max: placeholders?.size })
    },
  })

  // Load locales
  // localize({ en, fr, de, it })

  // Load Components
  Vue.component(`ValidationProvider`, ValidationProvider)
  Vue.component(`ValidationObserver`, ValidationObserver)
}
