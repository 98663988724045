import HttpFactory from '../factory'

class ClientModule extends HttpFactory {
  RESOURCE = `/clients`
  async getUsers() {
    return await this.raw(`GET`, `${this.RESOURCE}/users/`)
  }

  async createNewUser(account) {
    return await this.raw(`POST`, `${this.RESOURCE}/users/`, account)
  }

  async updateUser(user) {
    return await this.raw(`PUT`, `${this.RESOURCE}/users/${user.id}/`, user)
  }

  async resetPassword(email) {
    return await this.raw(`POST`, `client_request_reset_password`, {
      email
    })
  }

  async documents() {
    return await this.call(`GET`, `${this.RESOURCE}/documents/`)
  }

  async saveDocuments(params) {
    return await this.call(`post`, `${this.RESOURCE}/documents/`, params)
  }

  async exportUsersList(status) {
    return await this.raw(`GET`, `${this.RESOURCE}/users/export/${status}`)
  }

  async consultScreening(id, lang = null) {
    const url = !!lang ? `${this.RESOURCE}/screenings/${id}/?lang=${lang}` : `${this.RESOURCE}/screenings/${id}/`
    return await this.call(`get`, url)
  }
}

export default ClientModule
