// Permissions Store
import Vue from 'vue'

export const state = () => ({})

export const getters = {
  getCurrentRoles: (state, getters, rootState, rootGetters) =>
    rootGetters[`companies/getCurrentCompanyInfos`]?.roles || [],
  getCurrentRolesFromGate: () => Vue.gates.getRoles(),
  getRolesFor: (state, getters, rootState, rootGetters) => (company) =>
    rootGetters[`companies/getUserCompanies`][company]?.roles,
  getCurrentPermissions: (state, getters, rootState, rootGetters) =>
    rootGetters[`companies/getCurrentCompanyInfos`]?.permissions || [],
  getCurrentPermissionsFromGate: () => Vue.gates.getPermissions(),
  getPermissionsFor: (state, getters, rootState, rootGetters) => (company) =>
    rootGetters[`companies/getUserCompanies`][company]?.permissions,
  userHasRoleFromGate: () => (roles) => {
    return Vue.gates.hasRole(roles)
  },
  userHasRole: (state, getters, rootState, rootGetters) => (roles) => {
    const set = new Set()
    for (const company in rootGetters[`companies/getUserCompanies`]) {
      for (const role in company.roles) {
        set.add(role)
      }
    }
    const list = Array.from(set)
    return Array.isArray(roles) ? roles.some((r) => list.includes(r)) : list.includes(roles)
  },
  userHasPermission: (state, getters, rootState, rootGetters) => (permissions) => {
    const set = new Set()
    for (const company in rootGetters[`companies/getUserCompanies`]) {
      for (const permission in company.permissions) {
        set.add(permission)
      }
    }
    const list = Array.from(set)
    return Array.isArray(permissions) ? permissions.some((r) => list.includes(r)) : list.includes(permissions)
  },
  userIs: (state, getters, rootState, rootGetters) => (roles, targetCompany) => {
    const company = targetCompany !== undefined ? targetCompany : rootGetters[`companies/getCurrentCompany`]
    if (rootGetters[`companies/userHasCompany`](company)) {
      return Array.isArray(roles)
        ? roles.some((r) => rootGetters[`companies/getUserCompanies`][company]?.roles.includes(r))
        : rootGetters[`companies/getUserCompanies`][company]?.roles.includes(roles)
    }
    return false
  },
  userIsClient: (state, getters) =>
    getters.userIs([`client`, `master`, `consultonly`, `masteradmin`, `enfantadmin`, `kamclient`]),
  userIsMaster: (state, getters) => getters.userIs([`master`, `masteradmin`, `enfantadmin`, `kamclient`]),
  userIsCandidate: (state, getters) => getters.userIs(`candidate`),
  userIsCollab: (state, getters) => getters.userIs([`screener`, `kamclient`, `superuser`, `analyst`, `sales`, `dba`]),
  userIsScreener: (state, getters) => getters.userIs(`screener`),
  userIsMasterAdmin: (state, getters) => getters.userIs(`masteradmin`),
  userIsEnfantAdmin: (state, getters) => getters.userIs(`enfantadmin`),
  userIsKam: (state, getters) => getters.userIs(`kamclient`),
  userIsConsultonly: (state, getters) => getters.userIs(`consultonly`),
  userIsResponsible: (state, getters, rootState) => (screening) => {
    const userEmail = rootState?.auth?.user?.email
    return !!userEmail && userEmail === screening?.client_user?.email
  },
  userIsExternal: (state, getters, rootState, rootGetters) => !!rootState.external.token || !!rootState.marketplaceExternal.token,
  userIsGuest: (state, getters) => getters.userHasRoleFromGate(`guest`)
}
export const mutations = {}
export const actions = {}
