
  export default {
    props: {
      iconName: {
        type: String,
        default: `box`
      },
      width: {
        type: [Number, String],
        default: 18
      },
      height: {
        type: [Number, String],
        default: 18
      },
      viewBox: {
        type: [String],
        default: `0 0 1920 354.75`
      },
      iconColor: {
        type: String,
        default: `currentColor`
      }
    }
  }
