import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _587e53d2 = () => interopDefault(import('../pages/accounting/index.vue' /* webpackChunkName: "pages/accounting/index" */))
const _9aa7daa0 = () => interopDefault(import('../pages/auth_error.vue' /* webpackChunkName: "pages/auth_error" */))
const _b3483bf8 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _5b712157 = () => interopDefault(import('../pages/candidate/index.vue' /* webpackChunkName: "pages/candidate/index" */))
const _2a493166 = () => interopDefault(import('../pages/client/index.vue' /* webpackChunkName: "pages/client/index" */))
const _62bc7c35 = () => interopDefault(import('../pages/company-settings/index.vue' /* webpackChunkName: "pages/company-settings/index" */))
const _ae5e879e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _4417d5b0 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _7dcae98d = () => interopDefault(import('../pages/external/index.vue' /* webpackChunkName: "pages/external/index" */))
const _40a7c53a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2161ea89 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _df9efc42 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _1ec34f78 = () => interopDefault(import('../pages/my-settings/index.vue' /* webpackChunkName: "pages/my-settings/index" */))
const _3a869a1d = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _6069161b = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _0e4704b3 = () => interopDefault(import('../pages/client/documents.vue' /* webpackChunkName: "pages/client/documents" */))
const _7cca9cb1 = () => interopDefault(import('../pages/marketplace/checks/index.vue' /* webpackChunkName: "pages/marketplace/checks/index" */))
const _1974a94e = () => interopDefault(import('../pages/marketplace/external.vue' /* webpackChunkName: "pages/marketplace/external" */))
const _e8c42156 = () => interopDefault(import('../pages/my-settings/reset_password.vue' /* webpackChunkName: "pages/my-settings/reset_password" */))
const _c1e1e6ba = () => interopDefault(import('../pages/onboarding/get_started.vue' /* webpackChunkName: "pages/onboarding/get_started" */))
const _4df5198a = () => interopDefault(import('../pages/onboarding/initial_password.vue' /* webpackChunkName: "pages/onboarding/initial_password" */))
const _f1ee2866 = () => interopDefault(import('../pages/screenings/new.vue' /* webpackChunkName: "pages/screenings/new" */))
const _443d442d = () => interopDefault(import('../pages/marketplace/checks/create.vue' /* webpackChunkName: "pages/marketplace/checks/create" */))
const _77002223 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _31ebd14e = () => interopDefault(import('../pages/marketplace/checks/_id.vue' /* webpackChunkName: "pages/marketplace/checks/_id" */))
const _735316e7 = () => interopDefault(import('../pages/screenings/_id.vue' /* webpackChunkName: "pages/screenings/_id" */))
const _6937cc08 = () => interopDefault(import('../pages/sso/_providerID.vue' /* webpackChunkName: "pages/sso/_providerID" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accounting",
    component: _587e53d2,
    name: "accounting"
  }, {
    path: "/auth_error",
    component: _9aa7daa0,
    name: "auth_error"
  }, {
    path: "/callback",
    component: _b3483bf8,
    name: "callback"
  }, {
    path: "/candidate",
    component: _5b712157,
    name: "candidate"
  }, {
    path: "/client",
    component: _2a493166,
    name: "client"
  }, {
    path: "/company-settings",
    component: _62bc7c35,
    name: "company-settings"
  }, {
    path: "/contact",
    component: _ae5e879e,
    name: "contact"
  }, {
    path: "/dashboard",
    component: _4417d5b0,
    name: "dashboard"
  }, {
    path: "/external",
    component: _7dcae98d,
    name: "external"
  }, {
    path: "/login",
    component: _40a7c53a,
    name: "login"
  }, {
    path: "/logout",
    component: _2161ea89,
    name: "logout"
  }, {
    path: "/marketplace",
    component: _df9efc42,
    name: "marketplace"
  }, {
    path: "/my-settings",
    component: _1ec34f78,
    name: "my-settings"
  }, {
    path: "/onboarding",
    component: _3a869a1d,
    name: "onboarding"
  }, {
    path: "/redirect",
    component: _6069161b,
    name: "redirect"
  }, {
    path: "/client/documents",
    component: _0e4704b3,
    name: "client-documents"
  }, {
    path: "/marketplace/checks",
    component: _7cca9cb1,
    name: "marketplace-checks"
  }, {
    path: "/marketplace/external",
    component: _1974a94e,
    name: "marketplace-external"
  }, {
    path: "/my-settings/reset_password",
    component: _e8c42156,
    name: "my-settings-reset_password"
  }, {
    path: "/onboarding/get_started",
    component: _c1e1e6ba,
    name: "onboarding-get_started"
  }, {
    path: "/onboarding/initial_password",
    component: _4df5198a,
    name: "onboarding-initial_password"
  }, {
    path: "/screenings/new",
    component: _f1ee2866,
    name: "screenings-new"
  }, {
    path: "/marketplace/checks/create",
    component: _443d442d,
    name: "marketplace-checks-create"
  }, {
    path: "/",
    component: _77002223,
    name: "index"
  }, {
    path: "/marketplace/checks/:id",
    component: _31ebd14e,
    name: "marketplace-checks-id"
  }, {
    path: "/screenings/:id?",
    component: _735316e7,
    name: "screenings-id"
  }, {
    path: "/sso/:providerID?",
    component: _6937cc08,
    name: "sso-providerID"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
