import HttpFactory from '../factory'

class DocumentsModule extends HttpFactory {
  async getOne(downloadLink) {
    return await this.raw(`GET`, `${downloadLink}`)
  }

  async getAll({
    url = `${this.RESOURCE}`,
    params = {
      // pageSize: 20,
      // pageNumber : 1,
    }
  } = {}) {
    const paramsString = this.paramsArray(params)
    const separator = url.includes(`?`) ? `&` : `?`
    const finalUrl = `${url}${separator}${paramsString}`
    return await this.raw(`get`, finalUrl)
  }

  async delete(downloadLink) {
    return await this.raw(`GET`, `${downloadLink}`)
  }
}

export default DocumentsModule
