import HttpFactory from '../factory'

class TransactionsModule extends HttpFactory {
  RESOURCE = `/transactions`
  async get() {
    return await this.call(`get`, `${this.RESOURCE}/`)
  }

  async export(settings) {
    return await this.call(`put`, `${this.RESOURCE}/export/`, settings)
  }
}

export default TransactionsModule
