export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"English","code":"en","iso":"en-US","file":"en.lang.js"},{"name":"Français","code":"fr","iso":"fr-FR","file":"fr.lang.js"},{"name":"Deutsch","code":"de","iso":"de-DE","file":"de.lang.js"},{"name":"Italiano","code":"it","iso":"it-IT","file":"it.lang.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"en","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"English","code":"en","iso":"en-US","file":"en.lang.js"},{"name":"Français","code":"fr","iso":"fr-FR","file":"fr.lang.js"},{"name":"Deutsch","code":"de","iso":"de-DE","file":"de.lang.js"},{"name":"Italiano","code":"it","iso":"it-IT","file":"it.lang.js"}],
  localeCodes: ["en","fr","de","it"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "l",
  6: "a",
  7: "n",
  8: "g",
  9: ".",
  10: "j",
  11: "s",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "l",
  19: "a",
  20: "n",
  21: "g",
  22: "/",
  23: "e",
  24: "n",
  25: ".",
  26: "l",
  27: "a",
  28: "n",
  29: "g",
  30: ".",
  31: "j",
  32: "s",
  33: "\"",
  34: ",",
  35: "\"",
  36: "f",
  37: "r",
  38: ".",
  39: "l",
  40: "a",
  41: "n",
  42: "g",
  43: ".",
  44: "j",
  45: "s",
  46: "\"",
  47: ":",
  48: "\"",
  49: ".",
  50: ".",
  51: "/",
  52: "l",
  53: "a",
  54: "n",
  55: "g",
  56: "/",
  57: "f",
  58: "r",
  59: ".",
  60: "l",
  61: "a",
  62: "n",
  63: "g",
  64: ".",
  65: "j",
  66: "s",
  67: "\"",
  68: ",",
  69: "\"",
  70: "d",
  71: "e",
  72: ".",
  73: "l",
  74: "a",
  75: "n",
  76: "g",
  77: ".",
  78: "j",
  79: "s",
  80: "\"",
  81: ":",
  82: "\"",
  83: ".",
  84: ".",
  85: "/",
  86: "l",
  87: "a",
  88: "n",
  89: "g",
  90: "/",
  91: "d",
  92: "e",
  93: ".",
  94: "l",
  95: "a",
  96: "n",
  97: "g",
  98: ".",
  99: "j",
  100: "s",
  101: "\"",
  102: ",",
  103: "\"",
  104: "i",
  105: "t",
  106: ".",
  107: "l",
  108: "a",
  109: "n",
  110: "g",
  111: ".",
  112: "j",
  113: "s",
  114: "\"",
  115: ":",
  116: "\"",
  117: ".",
  118: ".",
  119: "/",
  120: "l",
  121: "a",
  122: "n",
  123: "g",
  124: "/",
  125: "i",
  126: "t",
  127: ".",
  128: "l",
  129: "a",
  130: "n",
  131: "g",
  132: ".",
  133: "j",
  134: "s",
  135: "\"",
  136: "}",
}

export const localeMessages = {
  'en.lang.js': () => import('../../lang/en.lang.js' /* webpackChunkName: "lang-en.lang.js" */),
  'fr.lang.js': () => import('../../lang/fr.lang.js' /* webpackChunkName: "lang-fr.lang.js" */),
  'de.lang.js': () => import('../../lang/de.lang.js' /* webpackChunkName: "lang-de.lang.js" */),
  'it.lang.js': () => import('../../lang/it.lang.js' /* webpackChunkName: "lang-it.lang.js" */),
}
