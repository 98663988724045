import * as Sentry from '@sentry/browser'
import errorRepository from './errorRepository'
import { useNuxtApp } from '#app'

class HttpFactory {
  $fetch

  constructor(fetcher = fetch) {
    this.$fetch = this.intercept(fetcher)
  }

  intercept(fetcher) {
    // fetcher.
    return {
      ...fetcher, // copier toutes les méthodes originales
      default: this.interceptMethod(fetcher),
      raw: this.interceptMethod(fetcher.raw)
    }
  }

  testonRequest() {}

  interceptMethod(method) {
    return async (url, options) => {
      // Avant la requête
      console.log(`Starting request`, url, options)
      // Exécuter la requête
      try {
        const response = await method(url, options)
        this.onRequest(url, options)
        // Après la requête
        console.log(`Finished request`, response)
        return response
      } catch (error) {
        // En cas d'erreur
        this.handleError(error)
      }
    }
  }

  onRequest(url, options) {
    const { store, $auth } = useNuxtApp().nuxt2Context
    if (!$auth.loggedIn) return
    if (url === `external`) {
      store.commit(`clearAutoLogout`)
      store.commit(`clearTimeoutLogout`)
      store.commit(`clearTimeoutMessageLogout`)
      return null
    }
    store.dispatch(`refreshSetTimeout`)
  }

  /**
   * method - GET, POST, PUT
   * URL
   **/
  call(method, url, data, extras = {}) {
    return this.$fetch.default(url, { method, body: data, ...extras })
  }

  raw(method, url, data, extras = {}) {
    return this.$fetch.raw(url, { method, body: data, ...extras })
  }

  handleError(error) {
    console.error(`Une erreur s'est produite lors de la requête :`, error)
    Sentry.captureException(error)
    errorRepository({ error })
    throw error
  }

  paramsArray(params) {
    return Object.entries(params)
      .map(([key, value]) => {
        if (value === null || value === ``) {
          return null
        } else if (Array.isArray(value)) {
          return value.map((item) => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`).join(`&`)
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      })
      .filter(Boolean)
      .join(`&`)
  }
}
export default HttpFactory
