import HttpFactory from '../factory'

// filters cans contains more than values, for example when using multiple in a select, or a single item.
const getParams = (filters) => {
  let params = ``
  Object.entries(filters ?? {}).forEach(([fName, fValues], fIdx) => {
    if (Array.isArray(filters)) {
      fValues.forEach((value, vIdx) => {
        const sym = (fIdx === 0 && vIdx === 0) ? `?` : `&`
        params = params.concat(`${sym}${fName}=${value}`)
      })
    } else {
      const sym = fIdx === 0 ? `?` : `&`
      params = params.concat(`${sym}${fName}=${fValues}`)
    }
  })
  return params
}

class MarketplaceModule extends HttpFactory {

  RESOURCE = `marketplace`

  async getCheckCategories() {
    return await this.call(`get`, `${this.RESOURCE}/categories`)
  }

  async getChecks(categoryId, filters) {
    const params = getParams(filters)
    return await this.call(`get`, `${this.RESOURCE}/categories/${categoryId}/checks${params}`)
  }

  async getCandidateFom(payload) {
    const { check, } = payload
    return await this.call(`get`, `${this.RESOURCE}/form/${check.id}`)
  }

  async cancelCheck(checkId) {
    return await this.call(`get`, `${this.RESOURCE}/my_checks/${checkId}/cancel`)
  }

  async newCheckOrder(form) {
    return await this.call(`post`, `${this.RESOURCE}/checks/new`, form)
  }

  async getAllMyChecks(filters) {
    const params = getParams(filters)
    return await this.call(`get`, `${this.RESOURCE}/my_checks${params}`)
  }

  async getMyCheckDetails(id) {
    return await this.call(`get`, `${this.RESOURCE}/my_checks/${id}`)
  }

  async generateReport(uuid, data, endPoint) {
    const options = { responseType: `blob`}
    const url = `${this.RESOURCE}/${endPoint}/${uuid}`
    return await this.call(`post`, url, data, options);
  }

  async resendAccess(uuid) {
    return await this.call(`get`, `${this.RESOURCE}/my_checks/resend/${uuid}`);
  }

}

export default MarketplaceModule
