import HttpFactory from '../factory'

class DashboardModule extends HttpFactory {
  RESOURCE = `dashboard`
  async getChatsDossierInProgres() {
    return await this.call(`get`, `${this.RESOURCE}/charts/dossier_in_progress`)
  }

  async getChatsDossierInfo() {
    return await this.call(`get`, `${this.RESOURCE}/charts/dossier_info`)
  }

  async getChatsResults() {
    return await this.call(`get`, `${this.RESOURCE}/charts/results`)
  }

  async getChatsScreeningTime() {
    return await this.call(`get`, `${this.RESOURCE}/charts/screening_time`)
  }
}

export default DashboardModule
