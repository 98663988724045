import HttpFactory from '../factory'

class InputSearcherModule extends HttpFactory {
  async getResults(url, query) {
    return await this.call(`get`, `${url}?query=${query}`)
  }

}

export default InputSearcherModule
