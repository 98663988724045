export const state = () => ({
  list: []
})

export const getters = {}
export const mutations = {
  SET_PACKAGES(state, packages) {
    state.list = packages
  }
}
export const actions = {
  async getPackages({ commit }) {
    const result = await this.$api.packages.me()
    commit(`SET_PACKAGES`, result)
  }
}
