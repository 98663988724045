import * as Sentry from '@sentry/browser'
import { OpenIDConnectScheme } from '~auth/runtime'

function encodeQuery(queryObject) {
  return Object.entries(queryObject)
    .filter(([_key, value]) => typeof value !== `undefined`)
    .map(([key, value]) => encodeURIComponent(key) + (value != null ? `=` + encodeURIComponent(value) : ``))
    .join(`&`)
}

class InvalidGrant extends Error {
  constructor(message) {
    super(message)
    this.name = `InvalidGrant`
  }
}

export default class AeqAuthScheme extends OpenIDConnectScheme {
  login(args = {}) {
    if (!args.params) {
      args.params = {}
    }
    const { params, ...options } = args

    const myArgs = {
      params: {
        prompt: `login`,
        ...params
      },
      ...options
    }
    return super.login(myArgs)
  }

  async fetchUser() {
    console.log(`fetchUser from AEQ scheme`)
    if (!this.check().valid) {
      return
    }

    if (!this.options.fetchRemote && this.idToken.get()) {
      console.log(`Getting userinfo from id token`)
      const data = this.idToken.userInfo()
      this.$auth.setUser(data)
      return
    }

    if (!this.options.endpoints.userInfo) {
      this.$auth.setUser({})
      return
    }

    const { data } = await this.$auth.requestWith(this.name, {
      url: this.options.endpoints.userInfo
    })

    this.$auth.setUser(data)
  }

  async refreshTokens() {
    console.log(`WILL REFRESH`)
    const result = {}
    try {
      const response = await fetch(this.options.endpoints.token, {
        method: `POST`,
        headers: { 'Content-Type': `application/x-www-form-urlencoded` },
        credentials: `include`,
        body: new URLSearchParams({
          scopes: this.scope,
          client_id: this.options.clientId,
          grant_type: `refresh_token`
        })
      })
      const data = await response.json()
      if (!response.ok) {
        switch (data.error) {
          case `invalid_grant`:
            throw new InvalidGrant(data.error_description)
          default:
            throw new Error(`Network response was not OK, returned ${response.code}`)
        }
      }
      result.data = data
      this.updateTokens(response)
      this.token.set(result.data.access_token)
      console.log(`NEW TOKEN ${this.token.sync()}`)
      console.log(`REFRESH SUCCESS`)
      return Promise.resolve()
    } catch (error) {
      console.log(`REFRESH ERROR`)
      console.error(error)
      Sentry.captureException(error)
      this.$auth.callOnError(error, { method: `refreshToken` })
      return Promise.reject(error)
    }
  }

  logout() {
    if (this.options.endpoints.logout) {
      const opts = {
        id_token_hint: this.idToken.get(),
        post_logout_redirect_uri: this.logoutRedirectURI
      }
      const url = this.options.endpoints.logout + `?` + encodeQuery(opts)
      window.location.replace(url)
    }
    this.$auth.reset()
  }
}
