import HttpFactory from '../factory'

class ReleaseNoteModule extends HttpFactory {
  RESOURCE = `/release_note`

  async getReleaseNote(version) {
    return await this.call(`get`, `${this.RESOURCE}/get/${version}`)
  }

  async currentReleaseNote() {
    return await this.call(`get`, `${this.RESOURCE}/current`)
  }

  async seenReleaseNote() {
    return await this.call(`get`, `${this.RESOURCE}/seen`)
  }

  handleError(error) {
    if (error.response.status === 404) {
      throw error
    }
    return super.handleError(error)
  }
}

export default ReleaseNoteModule
