import HttpFactory from '../factory'

class InvoicesModule extends HttpFactory {
  RESOURCE = `/invoices`
  async get() {
    return await this.call(`get`, `${this.RESOURCE}/`)
  }

  async export(settings) {
    return await this.call(`put`, `${this.RESOURCE}/export/`, settings)
  }

  async addCredits(amount) {
    // return await this.$axios.post(`/invoices/credits/create`, { amount })
    return await this.call(`post`, `${this.RESOURCE}/credits/create`, { amount })
  }

  // async pdf(id) {
  //   return await this.call(`get`, `${this.RESOURCE}/${id}/`)
  // }
}

export default InvoicesModule
