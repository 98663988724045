import Middleware from './middleware';
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime';

// Active schemes
import { default as Scheme$457b } from '/home/vsts/work/1/s/schemes/aeqAuthScheme.js';
Middleware.auth = authMiddleware;
export default function (ctx, inject) {
  // Options
  var options = {
    "resetOnError": false,
    "ignoreExceptions": false,
    "scopeKey": "scope",
    "rewriteRedirects": true,
    "fullPathRedirect": true,
    "watchLoggedIn": true,
    "redirect": {
      "login": "/login",
      "logout": "/logout/",
      "home": "/",
      "callback": "/callback"
    },
    "vuex": {
      "namespace": "auth"
    },
    "cookie": {
      "prefix": "auth.",
      "options": {
        "path": "/"
      }
    },
    "localStorage": {
      "prefix": "auth."
    },
    "defaultStrategy": "aeq"
  };

  // Create a new Auth instance
  var $auth = new Auth(ctx, options);

  // Register strategies
  // aeq
  $auth.registerStrategy('aeq', new Scheme$457b($auth, {
    "clientId": "a0230809-429e-453c-9711-f3838fba9d6c",
    "clientSecret": "secret",
    "endpoints": {
      "configuration": "https://oauth.aequivalent.swiss/.well-known/openid-configuration",
      "userInfo": "https://backoffice.aequivalent.swiss/frontoffice/user_info/",
      "token": "https://backoffice.aequivalent.swiss/auth/token/"
    },
    "logoutRedirectUri": "https://app.aequivalent.swiss",
    "token": {
      "property": "access_token",
      "type": "Bearer",
      "maxAge": "600"
    },
    "refreshToken": {
      "property": "refresh_token",
      "tokenRequired": false,
      "maxAge": "2700"
    },
    "accessType": "offline",
    "responseType": "code",
    "grantType": "authorization_code",
    "scope": "openid offline",
    "codeChallengeMethod": "S256",
    "audience": "https://backoffice.aequivalent.swiss/frontoffice",
    "fetchRemote": true,
    "name": "aeq"
  }));

  // Inject it to nuxt context as $auth
  inject('auth', $auth);
  ctx.$auth = $auth;

  // Initialize auth
  return $auth.init().catch(function (error) {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return;
      }
      console.error('[ERROR] [AUTH]', error);
    }
  });
}