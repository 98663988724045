import { useNuxtApp } from '#app'

export const state = () => ({
  query: ``,
  results: [],
  chosenResult: null,
})

export const getters = {
  results: (state, payload) => state.results,
}

export const mutations = {
  setResults(state, results) {
    state.results = results 
  }
}

function isExternal() {
  const { store } = useNuxtApp().nuxt2Context
  return store.getters[`permissions/userIsExternal`]
}
export const actions = {
  async getResults({ commit }, payload) {
    let data;
    if (isExternal()) {
      data = await this.$api.inputSearcherExternal.getResults(payload.url, payload.query)
    } else {
      data = await this.$api.inputSearcher.getResults(payload.url, payload.query)
    }
    commit(`setResults`, data)
  }
}
