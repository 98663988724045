import HttpFactory from '../factory'

class MarketplaceExternal extends HttpFactory {
  RESOURCE = `/marketplace/external`
  async get() {
    return await this.raw(`GET`, `${this.RESOURCE}`)
  }

  async post(data) {
    return await this.raw(`PUT`, `${this.RESOURCE}/`, data)
  }
}

export default MarketplaceExternal
