import Vue from 'vue'
import faker from 'faker'

Vue.prototype.$mocks = {}

Vue.prototype.$mocks.getScreenings = function* (limit, filter) {
  let counter = 0
  while (counter < limit) {
    counter++
    const [title, firstName, lastName] = [faker.name.prefix(), faker.name.firstName(), faker.name.lastName()]
    const screening = {
      title,
      first_name: firstName,
      last_name: lastName,
      complete_name: `${title} ${firstName} ${lastName}`,
      job_title: faker.name.jobTitle(),
      company: faker.company.companyName(),
      package: faker.random.arrayElement([
        `Standard`,
        `Employee File Update`,
        `Basic`,
        `Enhanced`,
        `Employee File Update +`
      ]),
      status: faker.random.arrayElement([
        `Order Placed`,
        `Waiting for candidate`,
        `Information check`,
        `File in progress`,
        `Final report`,
        `Dossier cancelled`
      ])
    }

    if (filter !== undefined && Array.isArray(filter)) {
      for (const elem in screening) {
        if (!filter.includes(elem)) {
          delete screening[elem]
        }
      }
    }

    yield screening
  }
}
