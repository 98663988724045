// Drawer menu Store
export const state = () => ({
  drawerStatus: false,
  displayed: false
})

export const getters = {
  getDrawerStatus: (state) => state.drawerStatus,
  getDisplayed: (state) => state.displayed
}
export const mutations = {
  openDrawer(state) {
    state.drawerStatus = true
  },
  closeDrawer(state) {
    state.drawerStatus = false
  },
  toggleDrawerStatus(state) {
    state.drawerStatus = !state.drawerStatus
  },
  drawerDisplayed(state) {
    state.displayed = true
  },
  drawerDeactivated(state) {
    state.displayed = false
  }
}
export const actions = {}
