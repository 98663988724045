export const state = () => ({
  list: {},
  credits: 0,
  availableCredits: undefined,
  settings: {
    invoice_period: null,
    use_automatic_reinvoice: false,
    reinvoice_amount: null,
    currency: `CHF`,
    invoice_modes: null,
    account_statement: null
  },
  addressesList: []
})

export const getters = {
  credits(state) {
    return state.credits
  },
  availableCredits(state) {
    return state.availableCredits
  }
}
export const mutations = {
  SET_LIST(state, payload) {
    state.list = { ...payload }
  },
  ADD_LIST(state, payload) {
    state.list = { ...state.list, ...payload }
  },
  SET_CREDITS(state, credits) {
    state.credits = credits
  },
  SET_SETTINGS(state, settings) {
    state.credits = settings?.credits ?? state.credits
    state.availableCredits = settings?.available_credits ?? state.available_credits
    delete settings?.credits
    delete settings?.available_credits
    state.settings = { ...state.settings, ...settings }
  },
  SET_ADDRESSES_LIST(state, addressesList) {
    state.addressesList = [...addressesList]
  }
}
export const actions = {
  async getList({ commit }, payload) {
    const { namespace, api: url, params = {} } = payload
    const paramsString = this.$api.request.paramsArray(params)
    const separator = url.includes(`?`) ? `&` : `?`
    const finalUrl = `${url}${separator}${paramsString}`
    const data = await this.$api.request.call(`get`, finalUrl)
    commit(`ADD_LIST`, { [namespace]: data })
  },
  async getSettings({ commit }, { companyTag }) {
    const data = await this.$api.billing.getCompagnySettings(companyTag)
    commit(`SET_SETTINGS`, data)
  },
  async updateSettings({ commit }, { companyTag, params }) {
    const data = await this.$api.billing.updateCompagnySettings(companyTag, params)
    console.log(data)
  },
  async newAddress({ commit }, { companyTag, params }) {
    const data = await this.$api.addresses.addCompagnyAddress(companyTag, params)
    console.log(data)
  },

  async addCredits({ commit }, { amount }) {
    return await this.$api.invoices.addCredits(amount)
  },

  async getAddresses({ commit }, { companyTag }) {
    const data = await this.$api.addresses.getCompagnyAddresses(companyTag)
    commit(`SET_ADDRESSES_LIST`, data)
  },
  async updateAdress({ commit }, { companyTag, uuid, params }) {
    const data = await this.$api.addresses.updateCompagnyAddress(companyTag, uuid, params)
    console.log(data)
  }
}
