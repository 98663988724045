import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export default function errorRepository(_ref) {
  var error = _ref.error;
  // eslint-disable-next-line no-undef
  var app = useNuxtApp();
  var _app$nuxt2Context = app.nuxt2Context,
    i18n = _app$nuxt2Context.i18n,
    $flashMessage = _app$nuxt2Context.$flashMessage,
    route = _app$nuxt2Context.route,
    $auth = _app$nuxt2Context.$auth;
  var code = parseInt(error.response && error.response.status);
  console.log("app, error", app, error, code);
  if (code === 401 && route.path.includes("external")) {
    $flashMessage.error({
      title: i18n.t("messages.error"),
      message: i18n.t("messages.missing_token")
    });
  } else if (code === 401) {
    $flashMessage.error({
      title: i18n.t("messages.error"),
      message: i18n.t("messages.missing_token")
    });
    console.log("CATCH 401");
    $auth.refreshTokens().catch(function (e) {
      console.log("Request was unauthorized and cannot refresh token");
      $auth.logout();
    });
  } else if (code === 403) {
    var _error$data;
    $flashMessage.error({
      title: i18n.t("messages.error"),
      message: !!(error !== null && error !== void 0 && (_error$data = error.data) !== null && _error$data !== void 0 && _error$data.detail) ? error.data.detail : i18n.t("messages.forbidden")
    });
  } else if (code === 404) {
    $flashMessage.error({
      title: i18n.t("messages.error"),
      message: i18n.t("messages.page_not_found")
    });
  } else if (code >= 400 && code < 500) {
    // Go futher for others client errors
    throw error;
  } else if (code >= 500) {
    $flashMessage.error({
      title: i18n.t("messages.error"),
      message: i18n.t("messages.server_error")
    });
  } else {
    $flashMessage.error({
      title: i18n.t("messages.error"),
      message: i18n.t("messages.unknown_error")
    });
  }
}