import HttpFactory from '../factory'

class PackagesModule extends HttpFactory {
  RESOURCE = `/packages`
  async me() {
    return await this.call(`get`, `${this.RESOURCE}/`)
  }
}

export default PackagesModule
