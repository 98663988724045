// Companies Store
import { useRuntimeConfig } from '#imports'

export const state = () => ({
  userCompanies: null
})

export const getters = {
  getCurrentCompany: (state, getters, rootState) => rootState?.auth?.user?.current_company,
  getCurrentCompanyInfos: (state, getters, rootState, rootGetters) =>
    !rootGetters[`permissions/userIsExternal`] && rootState?.auth?.user?.current_company
      ? state?.userCompanies[rootState?.auth?.user?.current_company]
      : false,
  userHasCompany: (state, getters, rootState, rootGetters) => (targetCompany) => {
    return !rootGetters[`permissions/userIsExternal`] && targetCompany ? !!state?.userCompanies?.[targetCompany] : false
  },
  userHasCurrentCompany: (state, getters, rootState, rootGetters) =>
    !rootGetters[`permissions/userIsExternal`] && rootState?.auth?.user?.current_company
      ? !!getters?.userHasCompany(rootState?.auth?.user?.current_company)
      : false,
  getUserCompanies: (state) => state?.userCompanies,
  getSelectableCompanies: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[`permissions/userIsExternal`] && !!state?.userCompanies) {
      return Object.keys(state?.userCompanies).reduce((filteredCompanies, key) => {
        if (state?.userCompanies[key]?.selectable) {
          filteredCompanies[key] = state.userCompanies[key]
        }
        return filteredCompanies
      }, {})
    }
    return {}
  }
}
export const mutations = {
  setUserCompanies(state, value) {
    state.userCompanies = !!value ? value : null
  }
}

const characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`
function randomString(length) {
  let result = ``
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const actions = {
  changeCompany({ state, commit }, company) {
    const strategy = this.$auth.strategy
    const config = useRuntimeConfig()
    console.log(config)
    const { endpoints, responseType, clientId, redirectUri, scope, codeChallengeMethod, audience } = strategy.options
    const authorizationUrlWithParams = new URL(endpoints.authorization)
    authorizationUrlWithParams.searchParams.append(`protocol`, `oauth2`)
    authorizationUrlWithParams.searchParams.append(`response_type`, responseType)
    authorizationUrlWithParams.searchParams.append(`client_id`, clientId)
    authorizationUrlWithParams.searchParams.append(`redirectUri`, redirectUri)
    authorizationUrlWithParams.searchParams.append(`code_challenge_method`, codeChallengeMethod)
    authorizationUrlWithParams.searchParams.append(`state`, randomString(10))
    authorizationUrlWithParams.searchParams.append(`audience`, audience)
    authorizationUrlWithParams.searchParams.append(`scope`, `${scope} company.${company}`)
    this.commit(`toggleNeedFetchUser`)
    window.location.href = authorizationUrlWithParams.href
  }
}
