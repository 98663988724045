import HttpFactory from '../factory'

class ComponentDataModule extends HttpFactory {
  RESOURCE = `/component_data`
  async getCountries() {
    return await this.call(`get`, `${this.RESOURCE}/countries/`)
  }

  async getAccountType() {
    return await this.call(`get`, `${this.RESOURCE}/account_types/`)
  }
}

export default ComponentDataModule
