import HttpFactory from '../factory'

class ScreeningOrderModule extends HttpFactory {
  RESOURCE = `/screening/order`

  async get() {
    return await this.call(`get`, `${this.RESOURCE}/`)
  }

  async create(screening) {
    return await this.call(`post`, `${this.RESOURCE}/`, {
      form: screening
    })
  }
}

export default ScreeningOrderModule
