// External Store
export const state = () => ({
  token: null,
  screener: null,
  form: {},
  lang: null,
  headerMessage: null
})

export const getters = {
  getToken: (state) => state.token,
  getScreener: (state) => state.screener,
  getData: (state) => (token) => !!state.form?.[token] ? JSON.parse(state.form?.[token]) : null
}

export const mutations = {
  setToken(state, value) {
    const token = !!value ? value : null
    localStorage.setItem(`external.token`, token)
    state.token = token
  },
  setScreener(state, value) {
    state.screener = !!value ? value : null
  },
  setData(state, { token, data }) {
    if (state.form === null) {
      state.form = {}
    }
    state.form[token] = JSON.stringify(data)
  },
  removeData(state, token) {
    delete state.form[token]
  },
  setLang(state, value) {
    state.lang = !!value ? value : null
  },
  setHeaderMessage(state, value) {
    state.headerMessage = !!value ? value : null
  },
  clearToken(state) {
    localStorage.removeItem(`external.token`)
    state.token = null
  },
  clearScreener(state) {
    state.screener = null
  }
}

export const actions = {
  async getExternal({ commit, state }) {
    return await this.$api.external.get()
  },
  async putExternal({ commit, state }, data) {
    return await this.$api.external.update(data)
  }
}
