import Vue from 'vue'
import FileSaver from 'file-saver'
import moment from 'moment'

export default ({ $auth, app }) => {
  Vue.prototype.$helpers = {}

  // Put the first letter of a string in capital
  Vue.prototype.$helpers.ucfirst = function (text) {
    return typeof text === `string` ? text.charAt(0).toUpperCase() + text.slice(1) : ``
  }

  // Add a new line before the last occurrence of a symbol
  Vue.prototype.$helpers.loNewLine = function (text, symbol) {
    const regex = new RegExp(`\\${symbol}(?!.*\\${symbol}).*$`)
    return symbol !== undefined ? text.replace(regex, `\n$&`) : text
  }

  Vue.prototype.$helpers.snake = function (value) {
    return value.toLowerCase().split(` `).join(`_`)
  }

  const wrapInArray = function (v) {
    return v !== null && v !== undefined ? (Array.isArray(v) ? v : [v]) : []
  }

  // Return a new Array or wrap an existing value inside.
  // Does nothing if value is already an Array.
  Vue.prototype.$helpers.wrapInArray = wrapInArray

  // Add an invisible character before a list of given characters to force word-break
  Vue.prototype.$helpers.addBreakInText = function (text, chars, after = false) {
    const softHyphen = `\u00AD`;
    return chars.reduce((acc, char) => acc.replace(char, after ? `${char}${softHyphen}` : `${softHyphen}${char}`), text)
  }

  Vue.prototype.$helpers.parseTemplate = (expression, valueObj, formElements, ifEmptyString) => {
    if (!expression || typeof expression !== `string`) {
      return ifEmptyString
    }
    const value = expression.replace(/%\s?([^%\s]*)\s?%/g, (substring, value) => {
      if (!!value && typeof value === `string`) {
        const [label, options] = value.split(`|`)
        if (options !== undefined) {
          const [type, option] = options.split(`,`)
          switch (type) {
            case `date`:
              return !!valueObj[label]?.answer ? moment(valueObj[label].answer).format(option) : ``
            default:
              return !!valueObj[label]?.answer ? valueObj[label].answer : ``
          }
        } else if (!!formElements[label]?.answers_list && formElements[label].answers_list.length > 0) {
          const answersKey = valueObj[label]?.answer
          const answersList = formElements[label]?.answers_list
          const answersFilter = answersList.find((answers) => answers.value === answersKey)
          return !!answersFilter?.text ? answersFilter.text : ``
        } else {
          return !!valueObj[label]?.answer ? valueObj[label].answer : ``
        }
      } else {
        return ``
      }
    })
    return value === expression.replace(/%\s?([^%\s]*)\s?%/g, ``) ? ifEmptyString : value
  }

  // Return an sorted Array of elements
  // sortFunc is the sorting function used by the helper
  function sortElements(elements, sortFunc = (a, b) => a[1] - b[1]) {
    const sortArray = []
    const result = Array.isArray(elements) ? [] : {}

    for (const element in elements) {
      if (!!elements[element].form_elements) {
        elements[element].form_elements = sortElements(elements[element].form_elements, sortFunc)
      }
      if (!!elements[element].block_content) {
        elements[element].block_content = sortElements(elements[element].block_content, sortFunc)
      }
      sortArray.push([element, elements[element]])
    }
    sortArray.sort(sortFunc)
    sortArray.forEach((element) => {
      result[element[0]] = element[1]
    })
    return result
  }

  Vue.prototype.$helpers.sortElements = sortElements

  Vue.prototype.$helpers.toFileSize = function (fileSize, approx = false) {
    let size, unit

    if (typeof fileSize !== `number`) {
      size = 0
      unit = app.i18n.t(`plugins.kB`)
    }

    if (fileSize < 999) {
      size = Math.round(fileSize * 100) / 100
      unit = app.i18n.t(`plugins.B`)
    } else if (fileSize < 999999) {
      fileSize /= 1000
      size = Math.round(fileSize * 100) / 100
      unit = app.i18n.t(`plugins.kB`)
    } else if (fileSize < 999999999) {
      fileSize /= 1000000
      size = Math.round(fileSize * 100) / 100
      unit = app.i18n.t(`plugins.MB`)
    } else if (fileSize < 999999999999) {
      fileSize /= 1000000000
      size = Math.round(fileSize * 100) / 100
      unit = app.i18n.t(`plugins.GB`)
    } else if (fileSize < 999999999999999) {
      fileSize /= 1000000000000
      size = Math.round(fileSize * 100) / 100
      unit = app.i18n.t(`plugins.TB`)
    }

    if (approx) {
      size = Math.round(size)
    }

    return `${approx && size > 0 ? `~` : ``}${size} ${unit}`
  }

  // Translate base64 string into an array of Bytes
  Vue.prototype.$helpers.blobToArrayBuffer = function (blobData, sliceSize = 512) {
    const byteArrays = []

    for (let offset = 0; offset < blobData.length; offset += sliceSize) {
      const slice = blobData.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return byteArrays
  }

  // Translate base64 string into an array of Bytes
  Vue.prototype.$helpers.b64ToArrayBuffer = function (b64Data, sliceSize = 512) {
    const rawB64 = b64Data.split(`base64,`)
    b64Data = rawB64.length > 1 ? rawB64[1] : rawB64[0]
    const byteCharacters = atob(b64Data)
    return Vue.prototype.$helpers.blobToArrayBuffer(byteCharacters, sliceSize)
  }

  // Translate base64 string into an fileReader
  Vue.prototype.$helpers.b64ToFile = async function (base64String, fileName) {
    const base64 = await fetch(base64String)
    const blob = await base64.blob()
    const file = new File([blob], fileName, {
      type: blob.type
    })
    return file
  }

  // Create image in a canva
  Vue.prototype.$helpers.createImageFromSrcToCanvas = function ({ src, canvas }) {
    const tempWidth = canvas.width
    const tempHeight = canvas.height
    canvas.width = src.videoWidth
    canvas.height = src.videoHeight
    canvas.getContext(`2d`).drawImage(src, 0, 0, src.videoWidth, src.videoHeight)
    const img = canvas.toDataURL(`image/jpeg`, 1)
    canvas.width = tempWidth
    canvas.height = tempHeight
    return img
  }

  // Helper to download a file from his binary representation
  Vue.prototype.$helpers.FileSaver = FileSaver

  Vue.prototype.$helpers.languagesLocales = function () {
    return app.i18n.locales.map((langue) => ({ ...langue, name: app.i18n.t(`plugins.${langue.name}`) }))
  }

  Vue.prototype.$helpers.changeLanguage = async function ({ lang, mute = false }) {
    if (!!app.i18n.locales.find((language) => language.code === lang)) {
      await app.i18n.setLocale(lang)
      if ($auth.loggedIn) {
        try {
          const { status, _data: data } = await app.$api.userInfo.update({ lang })
          $auth.setUser(data)
          if (!status && status !== 200) {
            await app.context.$flashMessage.error({
              title: app.i18n.t(`messages.error`),
              message: app.i18n.t(`messages.server_error`)
            })
          } else {
            if (!mute)
              await app.context.$flashMessage.success({
                title: app.i18n.t(`messages.success`),
                message: app.i18n.t(`messages.profile_updated`)
              })
          }
        } catch (e) {
          await app.context.$flashMessage.error({
            title: app.i18n.t(`messages.error`),
            message: app.i18n.t(`messages.server_error`)
          })
        }
      }
    }
  }

  Vue.prototype.$helpers.focus = function ({ element }) {
    if (!!element && element.length > 0) {
      if (element[0].type === `radio`) {
        const nameFirstRadio = element[0].name
        const firstElementChecked = [...element].find((el) => el.name === nameFirstRadio && !!el.checked)
        firstElementChecked ? firstElementChecked.focus() : element[0].focus()
      } else if (element[0].type === `checkbox`) {
        const firstElementNotDisable = [...element].find((el) => !el.disabled)
        firstElementNotDisable ? firstElementNotDisable.focus() : element[0].focus()
      } else {
        element[0].focus()
        const end = element[0].value.length
        if (!!end) {
          setTimeout(() => {
            element[0].setSelectionRange(end, end)
          })
        }
      }
    }
  }

  Vue.prototype.$helpers.removeNewDocumentFromScreening = function (screeening) {
    const screeeningWithoutNewDocument = {
      ...screeening,
      steps: [
        ...screeening.steps.map((step) => ({
          ...step,
          answers: step.answers.map((answer) => {
            const docs = Object.entries(step.form_elements).filter((item) => item[1].type === `document`)
            if (docs.length > 0) {
              const docsWithoutNew = { ...answer.data }
              docs.forEach((doc) => {
                const docKey = doc[0]
                const docInfo = answer.data[docKey]
                docsWithoutNew[docKey] = { ...docInfo, answer: { ...docInfo?.answer, new: [] } }
              })
              return {
                ...answer,
                data: {
                  ...docsWithoutNew
                }
              }
            } else {
              return {
                ...answer
              }
            }
          })
        }))
      ]
    }
    return screeeningWithoutNewDocument
  }

  Vue.prototype.$helpers.formatPhoneNumber = function (str) {
    const cleaned = (`` + str).replace(/\D/g, ``)
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})$/)
    return match ? `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}` : str
  }
}
