
  export default {
    name: `LoadingOverlay`,
    data() {
      return {
        loading: false,
        colorLoading: this.$vuetify.theme.currentTheme.secondary
      }
    },
    methods: {
      start() {
        this.loading = true
      },
      finish() {
        console.log(`finish loading!!!`)
        this.loading = false
      }
    }
  }
