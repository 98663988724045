
  import * as Sentry from '@sentry/browser'

  export default {
    layout: `empty`,
    props: {
      error: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        errorTitle: this.$t(`pages.layout.error_occured`),
        knownCode: [401, 403, 404, 500],
        timeoutDelay: 3000
      }
    },
    head() {
      const title = this.errorTitle
      return { title }
    },
    mounted() {
      Sentry.captureException(this.error)
      if (this.codeIsKnown()) {
        this.errorTitle = this.$t(`pages.http_${this.error.statusCode}`)
      } else if (this.error?.response?.data?.error === `invalid_grant`) {
        console.log(`invalid_grant`)
        this.logout()
      } else {
        this.errorTitle = this.$t(`messages.unknown_error`)
        console.error(`err`, this.error)
      }
      this.setTimeout()
    },
    methods: {
      logout: () => {
        this.$router.push({ path: `/logout` })
      },
      codeIsKnown() {
        return this.knownCode.includes((code) => code === this.error.statusCode)
      },
      redirectToHome() {
        this.$router.push({ path: `/` })
      },
      errorMessages() {
        this.$flashMessage.error({
          title: this.$t(`messages.error`),
          message: this.errorTitle
        })
      },
      setTimeout() {
        setTimeout(this.setTimeoutAction(), this.timeoutDelay)
      },
      setTimeoutAction() {
        this.errorMessages()
        this.redirectToHome()
      }
    }
  }
