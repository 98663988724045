import HttpFactory from '../factory'

class RatingModule extends HttpFactory {
  RESOURCE = `/rating`

  async sendFeedback(data) {
    return await this.call(`post`, `${this.RESOURCE}/create`, data)
  }
}

export default RatingModule
