import HttpFactory from '../factory'

class UserInfoModule extends HttpFactory {
  RESOURCE = `/user_info`
  async update(params) {
    return await this.raw(`put`, `${this.RESOURCE}/`, params)
  }
}

export default UserInfoModule
