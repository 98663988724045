import HttpFactory from '../factory'

class AuthModule extends HttpFactory {
  RESOURCE = `/auth`
  async login(credentials) {
    return await this.call(`POST`, `${this.RESOURCE}/login`, credentials)
  }

  async create(account) {
    return await this.call(`POST`, `${this.RESOURCE}/register`, account)
  }
}

export default AuthModule
