import * as Sentry from '@sentry/browser'

export const state = () => ({
  settings: {
    client_roles_names: [
      { value: `Master`, text: `Master user`, disabled: true },
      { value: `Client`, text: `Consult & order`, disabled: false },
      { value: `ConsultOnly`, text: `Consult only`, disabled: false },
      { value: `MasterAdmin`, text: `Master Admin`, disabled: true }
    ],
    two_factor_device_types: [
      { value: 1, text: `Email Authentication Code` },
      { value: 2, text: `SMS Authentication Code`, disabled: true }
    ],
    client_deletion_periods: [
      { value: 30, text: `30` },
      { value: 60, text: `60` },
      { value: 90, text: `90` }
    ]
  },
  error: null,
  loading: null,
  onboarding: {},
  refundContact: [],
  screeningForTheRefundContact: {},
  billingAddressOptions: {
    currencies: [],
    billing_periods: [],
    address_types: [],
    invoice_modes: []
  },
  countries: [],
  countriesLang: null,
  loadCountries: false
})

export const getters = {
  countries (state) {
    return state.countries
  }
}
export const mutations = {
  setSettings(state, payload) {
    state.settings = payload
  },
  refundContact(state, payload) {
    state.refundContact = [...payload]
  },
  screeningForTheRefundContact(state, payload) {
    state.screeningForTheRefundContact = {
      ...payload
    }
  },
  setOnboarding(state, payload) {
    state.onboarding = {
      ...payload,
      countries: payload.countries.sort((a, b) =>
        a.text.normalize(`NFD`).replace(/[\u0300-\u036F]/g, ``) >
        b.text.normalize(`NFD`).replace(/[\u0300-\u036F]/g, ``)
          ? 1
          : -1
      )
    }
  },
  setError(state, payload) {
    state.error = payload
  },
  setBillingAddressOptions(state, payload) {
    state.billingAddressOptions = payload
  },
  setLoading(state, payload) {
    state.loading = payload
  },
  setCountries(state, payload) {
    state.countries = payload
  },
  setCountriesLang(state, payload) {
    state.countriesLang = payload
  },
  toggleCountries(state) {
    state.loadCountries = !state.loadCountries
  }
}
export const actions = {
  async getSettings({ commit }) {
    commit(`setError`, null)
    commit(`setLoading`, true)
    try {
      const { status, statusText, _data: data } = await this.$api.formData.settings()
      if (!status && status !== 200) {
        throw new Error(statusText)
      }
      commit(`setSettings`, data)
    } catch (error) {
      commit(`setError`, error.message)
      commit(`setMe`, [])
    } finally {
      commit(`setLoading`, false)
    }
  },
  async onboardingGetOptions({ commit }) {
    const lang = this.$i18n.locale
    const data = await this.$api.onboarding.formData(lang)
    commit(`setOnboarding`, data)
  },
  async billingAddressOptions({ commit }) {
    const data = await this.$api.formData.billingAddressOptions()
    commit(`setBillingAddressOptions`, data)
  },
  async getscreeningsMeLight({ commit }) {
    const data = await this.$api.screenings.meLight()
    commit(`refundContact`, data)
  },
  async getRefundContactByUUID({ commit }, payload) {
    const data = await this.$api.contact.getRefundContactByUUID(payload.UUID)
    commit(`screeningForTheRefundContact`, data)
  },

  async getCountries({ commit, state }) {
    const lang = this.$i18n.locale
    if (state?.countries?.length > 0 && state.countriesLang === lang) {
      return state.countries
    }
    if (!!state.loadCountries) {
      return
    }
    try {
      commit(`toggleCountries`)
      const data = await this.$api.componentData.getCountries()
      const countries = Object.entries(data).map(([value, text]) => ({ value, text }))
      commit(`setCountries`, countries)
      commit(`setCountriesLang`, lang)

      commit(`toggleCountries`)
      return countries
    } catch (e) {
      console.log(e)
      Sentry.captureException(e)
      commit(`toggleCountries`)
    }
  }
}
