export const state = () => ({})

export const getters = {}
export const mutations = {}
export const actions = {
  messageSuccess({ state }, { message = ``, title = this.$i18n.t(`messages.success`), ...props }) {
    this.$flashMessage.success({ ...props, message, title })
  },
  messageError({ state }, { title = this.$i18n.t(`messages.error`), message = ``, ...props }) {
    this.$flashMessage.error({ ...props, message, title })
  },
  messageInfo({ state }, { message = ``, title = this.$i18n.t(`messages.info`), ...props }) {
    this.$flashMessage.info({ ...props, message, title })
  }
}
