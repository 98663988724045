import HttpFactory from '../factory'

class StateOfService extends HttpFactory {
  RESOURCE = `/state_of_service`
  async get(id) {
    return await this.call(`get`, `${this.RESOURCE}/`)
  }
}

export default StateOfService
